import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';
import TextPlugin from 'gsap/TextPlugin';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// component
import { Button } from '../../components/Button.js';
import { Heading, SubHeading, AuthSvg } from './components/styles';
import VendorLogos from './components/VendorLogos';
import { useIsomorphicLayoutEffect } from '../../hooks/useIsomorphicLayoutEffect.js';
//Images
import AuthenticateLogo from '../../components/AuthenticateLogo.js';
import Fingerprint from '../../images/product-assets/fingerprint.svg';

const Container = styled.main`
    background: url(${Fingerprint});
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
`;

const HeroContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto 110px;
    padding: 110px 24px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto 110px;
    padding: 110px 24px 0;

    @media screen and (max-width: 820px) {
        flex-direction: column-reverse;
        margin: 0 auto 65px;
        padding-top: 65px;
        text-align: center;
    }
    @media screen and (max-width: 820px) {
        flex-direction: column-reverse;
        margin: 0 auto 65px;
        padding-top: 65px;
        text-align: center;
    }
`;

const MainContent = styled.div`
    @media screen and (max-width: 820px) {
        margin-top: 36px;
    }
`;

const VideoContainer = styled.div`
    width: 460px;
    height: auto;
    aspect-ratio: 16 / 9;
    display: flex;
    justify-content: center;
    box-shadow: 0 2px 20px 0 rgba(38, 50, 140, 0.1);
    border: 1px solid rgba(12, 26, 104, 0.4);
    border-radius: 16px;
    overflow: hidden;

    @media screen and (max-width: 520px) {
        width: 100%;
        height: auto;
    }
    @media screen and (max-width: 520px) {
        width: 100%;
        height: auto;
    }
`;

const ButtonGroup = styled.div`
    margin-top: 32px;
    padding: 4px 0;

    @media screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-top: 30px;
    }
`;

const MainButton = styled(Button)`
    transition: all 0.2s;
    background-color: #252963;
    &:hover {
        transform: scale(1.1);
    }

    :first-child {
        margin-right: 18px;
    }

    ${({ secondary }) =>
        secondary &&
        css`
            display: inline-flex;
            border: none;
            justify-content: center;
            align-items: center;
            gap: 4px;
            color: #252963;
            background: none;

            &:hover {
                outline: 1px solid #252963;
                transform: scale(1);
            }
        `}

    @media screen and (max-width: 500px) {
        display: block;
        width: 188px;
        text-align: center;
        margin: 0 auto;

        :first-child {
            margin: 0 auto;
        }

        ${({ secondary }) =>
            secondary &&
            css`
                display: inline-flex;
                border: 1px solid rgba(0, 0, 0, 0.5);
            `};
    }
`;

const HeroHeading = styled(Heading)`
    font-size: 54px;
    display: inline;
    color: #252963;

    @media screen and (max-width: 820px) {
        font-size: 24px;
        padding: 0;
    }
`;

const HeroSubHeading = styled(SubHeading)`
    margin-top: 24px;
    max-width: max-content;
    @media screen and (max-width: 820px) {
        margin-top: 16px;
        br {
            display: none;
        }
    }
`;

const SecondText = styled.p`
    font-size: 56px;
    color: #4694c4;
    font-weight: 500;
    display: block;

    span {
        color: #252963;
    }

    @media screen and (max-width: 500px) {
        font-size: 24px;
    }
`;

const Cursor = styled.span`
    font-size: 56px;
    color: #26328c;

    @media screen and (max-width: 500px) {
        font-size: 24px;
    }
`;

const LinkWrap = styled.a`
    text-decoration: none;
    color: ${(props) => (props.secondary ? '#212121' : 'white')};
`;

function HeroSection() {
    const heroTextRef = useRef(null);
    const spanRef = useRef(null);
    const cursorRef = useRef(null);

    useIsomorphicLayoutEffect(() => {
        const words = [
            'Stop identity fraud',
            'Prevent account theft',
            'Manage your risk',
            'Add trust and safety',
            'Ensure payment/delivery',
        ];

        gsap.registerPlugin(TextPlugin);

        const typingTl = gsap.timeline({ repeat: -1 });

        gsap.to(cursorRef.current, {
            opacity: 0,
            ease: 'power2.inOut',
            repeat: -1,
        });

        words.forEach((word) => {
            let tl = gsap.timeline({ repeat: 1, yoyo: true, repeatDelay: 0.5 });
            tl.to(heroTextRef.current, { duration: 3, text: word });
            typingTl.add(tl);
        });
    }, []);

    return (
        <Container id="home">
            <HeroContainer>
                <MainContent>
                    <div>
                        <HeroHeading secondary ref={heroTextRef} className="text"></HeroHeading>
                        <Cursor className="cursor" ref={cursorRef}>
                            |
                        </Cursor>
                        <SecondText ref={spanRef}>
                            <span> with</span> authenticate
                        </SecondText>
                    </div>
                    <HeroSubHeading subHeading>
                        <AuthSvg>
                            <svg
                                width="24"
                                height="26"
                                viewBox="0 0 24 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M3.40381 15.6862L10.9317 17.5424L22.7318 9.7038L11.135 22.9058L3.40381 15.6862Z"
                                    fill="#212121"
                                />
                                <path
                                    d="M7.26807 15.5884L10.2153 7.69674C10.8104 6.0533 11.2706 4.39792 11.5969 2.73059C11.9931 4.13808 12.5999 5.93458 13.4159 8.12234L15.3682 13.3745L18.1549 11.5234L13.5043 0H9.84857L4.22803 14.8387L7.26807 15.5884V15.5884Z"
                                    fill="#212121"
                                />
                                <path
                                    d="M3.38691 17.0586L0 26H3.60342L5.94679 19.4487L3.38691 17.0586Z"
                                    fill="#212121"
                                />
                                <path
                                    d="M19.5181 14.8984L17.0039 17.7604L20.1338 26H24.0001L19.5181 14.8984Z"
                                    fill="#212121"
                                />
                            </svg>
                        </AuthSvg>
                        uthenticate<sup>®</sup> over 7,500 types of photo ID or passports <br />
                        from 196 countries, with optional facial recognition,&nbsp;
                        <br />
                        liveness detection, knowledge quizzes & background checks.
                    </HeroSubHeading>
                    <ButtonGroup>
                        <LinkWrap
                            href="https://verify.authenticating.com/onboarding"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <MainButton>
                                <AuthenticateLogo />
                            </MainButton>
                        </LinkWrap>
                        <LinkWrap
                            href="https://portal.authenticate.com/register"
                            target="_blank"
                            rel="noreferrer"
                            secondary
                        >
                            <MainButton secondary>
                                Get Started
                                <ChevronRightIcon style={{ width: '16px', height: '16px' }} />
                            </MainButton>
                        </LinkWrap>
                    </ButtonGroup>
                </MainContent>
                <VideoContainer>
                    <video
                        width="100%"
                        height="100%"
                        title="Authenticate Introduction"
                        controls
                        allow="encrypted-media; fullscreen;"
                        allowfullscreen
                        frameborder="0"
                    >
                        <source
                            src="https://cdn.authenticating.com/public/website/Authenticate+Horizontal.mp4"
                            type="video/mp4"
                        ></source>
                    </video>
                </VideoContainer>
            </HeroContainer>
            <VendorLogos />
        </Container>
    );
}

export default HeroSection;
